import { isMobile, isMobileOnly } from "react-device-detect";
import { RiStockFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { LuCircuitBoard } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const DashboardOverviewInside = ({ context }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={`overviewInside pt-4 ${
        window?.location?.pathname?.includes("/overview")
          ? "d-flex justify-content-between"
          : ""
      } `}
    >
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox">
            <div className={isMobileOnly ? "mt-3 " : "mt-3"}>
              <span className=" eyebox">
                <BsPeopleFill color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div
              style={{ paddingInlineStart: "1rem" }}
              onClick={() => navigate(routes?.listMembers)}
            >
              <h6 className="total fw-light"> {t("dashboard.Members")}</h6>
              <h3 className=" fw-bold">{context?.data?.members}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox ">
            <div className={isMobileOnly ? "mt-3 " : "mt-3 "}>
              <span className=" eyebox">
                <LuCircuitBoard color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div
              style={{ paddingInlineStart: "1rem" }}
              onClick={() =>
                navigate(routes?.listBillBoardUrl({ board_status: "ACTIVE" }))
              }
            >
              <h6 className="total fw-light">{t("dashboard.BillBoards")}</h6>
              <h3 className="   fw-bold">{context?.data?.billboard}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox ">
            <div className={isMobileOnly ? "mt-3 " : "mt-3 "}>
              <span className=" eyebox">
                <LuCircuitBoard color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div
              style={{ paddingInlineStart: "1rem" }}
              onClick={() =>
                navigate(
                  routes?.listBillBoardUrl({ board_status: "NON_OPERATIONAL" })
                )
              }
            >
              <h6 className="total fw-light fit-content" style={{minWidth:"120px"}}>
                {window?.location?.pathname?.includes("/overview")
                  ? "Non Operational"
                  : t("filter.NON_OPERATIONAL")}
              </h6>
              <h3 className="   fw-bold">{context?.data?.non_operational}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox">
            <div className={isMobileOnly ? "mt-3 " : "mt-3"}>
              <span className=" eyebox">
                <GiTicket color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div
              style={{ paddingInlineStart: "1rem" }}
              onClick={() => navigate(routes?.listTickets)}
            >
              <h6 className="total fw-light">{t("dashboard.Tickets")}</h6>
              <h3 className=" fw-bold">{context?.data?.tickets}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox">
            <div className={isMobileOnly ? "mt-3 " : "mt-3 "}>
              <span className=" eyebox">
                <RiStockFill color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div
              style={{ paddingInlineStart: "1rem" }}
              onClick={() =>
                navigate(routes?.listBillBoardUrl({ board_status: "REQUEST" }))
              }
            >
              <h6 className="total fw-light">{t("dashboard.Pending")}</h6>
              <h3 className=" fw-bold">{context?.data?.requestedBillboard}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewInside;
